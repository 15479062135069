import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import axios from 'axios';


import Home from "./components/Home";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import About from "./components/About";
import News from "./components/News";

// import StudentPayment from "./components/studentpayment";
import Success from "./components/SuccessedPayment";
import Failure from "./components/failedPayment";

import { AuthProvider } from "./branches/branch1/context/AuthContext";
import Generate from "./branches/branch1/components/generate";
import Home1 from "./branches/branch1/components/Home";
import About1 from "./branches/branch1/components/About";
import Faculties1 from "./branches/branch1/components/Faculties";
import Admissions1 from "./branches/branch1/components/Admissions";
import Gallery1 from  "./branches/branch1/components/Gallery";
import News1 from "./branches/branch1/components/News";
import Contact1 from "./branches/branch1/components/Contact";
import Facilities1 from "./branches/branch1/components/Facilites";
import Alumina1 from "./branches/branch1/components/Alumina";
import Login1 from "./branches/branch1/components/Login";
import Register1 from "./branches/branch1/components/Register";

import Home2 from "./branches/branch2/components/Home";
// import Home3 from "./branches/branch3/components/Home";
// import Home4 from "./branches/branch4/components/Home";
// import Home5 from "./branches/branch5/components/Home"; 

function App() {
  const [images, setImages] = useState({ homeImages: [], galleryImages: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://sandeepanischools.in/mainPage/images'); // Replace with your API endpoint
        setImages(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching images:', err);
        setError('Failed to load images. Please try again later.');
        setLoading(true);
      }
    };
    fetchImages();
    setTimeout(() => setLoading(false), 5000);
  }, []);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }
  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }
  console.log(error);
  
  return (
    <AuthProvider>
      <Router>
          <Routes>

            {/* <Route path="/" element={<RazorpayPayment />} /> */}
            <Route path="/sandeepanihitechschool/generate" element={<Generate />} />
            <Route path="/" element={<Home homeImages={images.homeImages}/>} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gallery" element={<Gallery galleryImages={images.galleryImages} />} />
            <Route path="/about" element={<About />} />
            <Route path="/news" element={<News notificationImages={images.notificationImages}/>} />
            
            {/* <Route path="/fee" element={<StudentPayment />} /> */}
            <Route path="/failure" element={<Failure />} />
            <Route path="/success"  element={<Success />}  />
            {/* branch 1 */}
            
            <Route path="/sandeepanihitechschool" element={<Home1 />} />
            <Route path="/sandeepanihitechschool/gallery" element={<Gallery1 />} />
            <Route path="/sandeepanihitechschool/news" element={<News1 />} />
            <Route path="/sandeepanihitechschool/contact" element={<Contact1 />} />
            <Route path="/sandeepanihitechschool/about" element={<About1 />} />
            <Route path="/sandeepanihitechschool/faculties" element={<Faculties1 />} />
            <Route path="/sandeepanihitechschool/facilities" element={<Facilities1 />} />
            <Route path="/sandeepanihitechschool/alumina" element={<Alumina1 />} />
            <Route path="/sandeepanihitechschool/admissions" element={<Admissions1 />} />
            <Route path="/sandeepanihitechschool/login" element={<Login1 />} />
            <Route path="/sandeepanihitechschool/registration" element={<Register1 />} />


              {/* branch 2 */}
            <Route path="/sandeepaninationalpublicschool" element={<Home2 />} />
          

          </Routes>
      </Router>
      </AuthProvider>
  );
}

export default App;
