import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './failure.css';
const Failure = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/sandeepanihitechschool/generate"); 
    }, 8000);

    return () => clearTimeout(timer); 
  }, [navigate]);

  return ( 
    <div className="failure-container">
      <div className="failure-message">
        <div className="failure-icon">❌</div>
        <h1 className="failure-title">Payment Failed</h1>
        <div className="failure-text">We're sorry, your payment could not be processed.</div>
        <div className="failure-redirect">You will be redirected to the payment page shortly...</div>
      </div>
    </div>
  );
};

export default Failure;
