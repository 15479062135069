import Header1 from "./Header";
import Footer from "./Footer";
// import "../stylesheets/Faculties.css";
import image1 from '../../../assets/dummy.png';

// Sample data for faculty members
const facultiesData = [
  { id: 1, name: "Suma M", qualification: "MA, B.Ed, M.Ed", experience: "11 Years", img: image1 },
  { id: 2, name: "Veena S Kakaade", qualification: "MCA, Diploma In E & C", experience: "7 Years", img: image1 },
  { id: 3, name: "Shwetha H", qualification: "B.com, NTT", experience: "6 Years", img: image1 },
  { id: 4, name: "Anitha Praveen", qualification: "BA, NTT", experience: "15 Years", img: image1 },
  { id: 5, name: "Shruthi B", qualification: "MA, BEd", experience: "7 Years", img: image1 },
  { id: 6, name: "Rekha Shashikar", qualification: "BA, B.Ed", experience: "5 Years", img: image1 },
  { id: 7, name: "Sumana Mukeriee", qualification: "PhD", experience: "5 Years", img: image1 },
  { id: 8, name: "Ramamani K", qualification: "B.Ed, M.Sc", experience: "3 Years", img: image1 }
];

const Faculties = () => {
  return (
    <div>
      <Header1 />
      <div className="faculties-container-main">
        <div className="faculties-container-card">
          {facultiesData.map((faculty) => (
            <div className="faculty-card" key={faculty.id}>
              <img src={faculty.img} alt={faculty.name} className="faculty-image" />
              <div className="faculty-info">
                <h3 className="faculty-name">{faculty.name}</h3>
                <h6 className="faculty-qualification">{faculty.qualification}</h6>
                <h6 className="faculty-experience">{faculty.experience}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faculties;
