import Header1 from "./Header";
import Footer from "./Footer";
// import "../stylesheets/Facilities.css";
const Facilities = () => {
  return (
    <div>
      <Header1 />
      Here goes the facilities page
      <Footer />
    </div>
  );
};

export default Facilities;
