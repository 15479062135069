import Header1 from "./Header";
// import "../stylesheets/Gallery.css";
import Footer from "./Footer";
const Gallery = ({galleryImages}) => {
 
  return (
    <div>
      <Header1 />
    
      <div className="box1">
        <div className="rec1"></div>
        <div className="home-title2">School Uniform</div>
      </div>
      <div className="box1">
        <div className="rec1"></div>
        <div className="home-title2">School Campus</div>
      </div>
      <div className="box1">
        <div className="rec1"></div>
        <div className="home-title2">School Ground</div>
      </div>
      <div className="box1">
        <div className="rec1"></div>
        <div className="home-title2">School Bus</div>
      </div>
      <div className="box1">
        <div className="rec1"></div>
        <div className="home-title2">Kinder Garden</div>
      </div>
      <div className="box1">
        <div className="rec1"></div>
        <div className="home-title2">Annual Day</div>
      </div>
      <div className="box1">
        <div className="rec1"></div>
        <div className="home-title2">Rituals celebrated</div>
      </div>
      <div className="box1">
        <div className="rec1"></div>
        <div className="home-title2">Journeys</div>
      </div>
      <Footer />
    </div>
  ); 
};

export default Gallery;
