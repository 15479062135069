// import React, { createContext, useContext, useState, useEffect } from 'react';
// import axios from 'axios';
// import toast from 'react-hot-toast';

// interface AuthContextType {
//   user: any | null;
//   login: (email: string, password: string) => Promise<void>;
//   signup: (email: string, password: string) => Promise<void>;
//   logout: () => Promise<void>;
//   isAuthenticated: boolean;
//   isAdmin: boolean;
// }

// const AuthContext = createContext<AuthContextType | null>(null);

// export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
//   const [user, setUser] = useState<any | null>(null);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [isAdmin, setIsAdmin] = useState(false);

//   useEffect(() => {
//     const fetchSession = async () => {
//       try {
//         const response = await axios.get('/api/auth/session'); // Server endpoint to fetch user session
//         const { user } = response.data;
//         setUser(user);
//         setIsAuthenticated(!!user);
//         setIsAdmin(user?.email === 'santhosh7banja@gmail.com');
//       } catch (error) {
//         console.error('Failed to fetch session:', error);
//         setUser(null);
//         setIsAuthenticated(false);
//         setIsAdmin(false);
//       }
//     };

//     fetchSession();
//   }, []);

//   const signup = async (email: string, password: string) => {
//     try {
//       await axios.post('/api/auth/signup', { email, password }); // Call server API
//       toast.success('Account created successfully!');
//     } catch (error: any) {
//       toast.error(error.response?.data?.message || error.message);
//       throw error;
//     }
//   };

//   const login = async (email: string, password: string) => {
//     try {
//       const response = await axios.post('/api/auth/login', { email, password }); // Call server API
//       setUser(response.data.user);
//       setIsAuthenticated(true);
//       setIsAdmin(response.data.user.email === 'santhosh7banja@gmail.com');
//       toast.success('Logged in successfully!');
//     } catch (error: any) {
//       toast.error(error.response?.data?.message || error.message);
//       throw error;
//     }
//   };

//   const logout = async () => {
//     try {
//       await axios.post('/api/auth/logout'); // Call server API
//       setUser(null);
//       setIsAuthenticated(false);
//       setIsAdmin(false);
//       toast.success('Logged out successfully!');
//     } catch (error: any) {
//       toast.error(error.response?.data?.message || error.message);
//       throw error;
//     }
//   };

//   return (
//     <AuthContext.Provider value={{ user, login, signup, logout, isAuthenticated, isAdmin }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };


import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
// Context type definition
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await axios.get('/api/auth/session'); // Server endpoint to fetch user session
        const { user } = response.data;
        setUser(user);
        setIsAuthenticated(!!user);
        setIsAdmin(user?.email === 'santhosh7banja@gmail.com');
      } catch (error) {
        console.error('Failed to fetch session:', error);
        setUser(null);
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    };

    fetchSession();
  }, []); 

  const signup = async (email, password,firstName,lastName,mobile) => {
    try {
      await axios.post('https://sandeepanischools.in/api/auth/signup', { email, password,firstName,lastName,mobile }); // Call server API
      toast.success('Account created successfully!');
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
      throw error;
    }
  };

  const login = async (email, password) => {
    try { 
      const response = await axios.post('https://sandeepanischools.in/api/auth/login', { email, password }); // Call server API
      
      setUser(response.data.user);
      setIsAuthenticated(true);
      setIsAdmin(response.data.user.email === 'santhosh7banja@gmail.com');
      toast.success('Logged in successfully!');
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await axios.post('https://sandeepanischools.in/api/auth/logout'); // Call server API
      setUser(null);
      setIsAuthenticated(false);
      setIsAdmin(false);
      toast.success('Logged out successfully!');
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, signup, logout, isAuthenticated, isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
