import Header1 from "./Header";
import Footer from "./Footer";
// import "../stylesheets/Alumina.css";
const Alumina = () => {
  return (
    <div>
      <Header1 />
      Here goes the Alumina page 2
      <Footer />
    </div>
  );
};

export default Alumina;
