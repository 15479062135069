// import { BrowserRouter as  useNavigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header1 from "./Header";
import Footer from "./Footer";
// import "../stylesheets/Admissions.css";

const Admissions = () => {
  // const navigate = useNavigate();

  // const handleClick = () => {
  //   navigate("/sandeepanihitechschool/application"); 
  // };

  const navigate = useNavigate(); // Hook for navigation

  const handleApplicationClick = () => {
    navigate("/sandeepanihitechschool/application"); // Navigate to the Application component
  };

  const downloadImage = async (imageSrc, nameOfDownload ) => {
    try {
      const response = await fetch(imageSrc);
      const blobImage = await response.blob();
      const href = URL.createObjectURL(blobImage);

      const anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = nameOfDownload;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);

      alert("The Application form has been Downloaded")
    } catch (err) {
      console.log('Error downloading image: ', err);
    }
  };


  return (
    <div>
      <Header1 />
       <div className="admissionCont">
        
      <div className="heading">
        <div className="headingOne">Admissions for 2025-2026</div>
        <div className="headingTwo">Nursery - XI</div>
      </div>
      <div className="buttonCont">
        <button  className="downloadButton"  onClick={() => {
          downloadImage('https://s3-alpha-sig.figma.com/img/cae9/e970/9f4af2cec9ecdb1b9ffea5b6fccc27d9?Expires=1730073600&Key-Pair-className=APKAQ4GOSFWCVNEHN3O4&Signature=hhj~TD4PzXrjybvxmnq1aew7cMxbnn4Xo6~oT6D206Oz9HPjqMSjnWkoMIFB1Fazia5p4XdcuI2g5EBfbIzc~oje2718eGkAjd4nu7UAqxTqCPidQI5XY4x76TGsFwbH8MthO0JgwNUcoOyHqQtgw70oaTzqyFx8Q03PoHZZj9GbWnK~6g7TKUgkROdHyx0LUosD0m~baqfa4OKH3H-gN96H2XNT-muffkJlI6NE9Shilm61pVEpx-AnH3dlb~K3~8PpwqcYQ6FleSjzJNxIE55pTM-vordDJh9BefDIe1BLzE6BDET0JvfG2IEQtozvku-veJFN9k4N1pgxxN5saA__', 'form.png');
        }} >
          Download Application Form
        </button>
        <button className="onlineForm" onClick={handleApplicationClick} >
          Online Application Form
        </button>
        <button className="enquireNow">
          Enquire Now
        </button>
      </div>
      <hr />
      <div className="importantPointsContainer">
      <p className="importantPointsPara" >Important Points To Note Before Applying Online</p>
      </div>
      <div className="pointsContainer">
      <p className="documentPara">Below mentioned documents will be required to complete the Application form.</p>
      <ol>
        <li>Photo of student and parents.</li>
        <li>Aadhar card of student and parents.</li>
        <li>Voter ID of father or mother</li>
        <li>Previous year marks card of student.</li>
      </ol>
      <p className="notePara">Note: Submitting the application form does not confirm the admission of the child. A
        visit to the campus by the parents and child is mandatory to complete the next set of
        admission procedure.</p>
      </div>
      
        </div>
      <Footer />
    </div>
  );
};

export default Admissions;
