import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./generate.css"; // Import the CSS file
import Header1 from "./Header";
import Footer from "./Footer";
import { useAuth } from "../context/AuthContext";
const GenerateAmount = () => {
  const navigate = useNavigate();
  const {isAuthenticated} =useAuth();
  const [admissionNo, setAdmissionNo] = useState("");
  // const [totalAmount, setTotalAmount] = useState(5000);
  const [studentDetails, setStudentDetails] = useState(null);
  const [amount, setAmount] = useState(1000);

  const handleGenerateAmount = async () => {
    try {
      const response = await axios.post(
        "https://sandeepanischools.in/generate-amount",
        {
          admissionNo,
        }
      );

      setStudentDetails(response.data);
    } catch (error) {
      console.error("Error generating amount:", error);
      alert(
        "Error generating amount. Please check the admission number or try again."
      );
    }
  };

  const [razorpayLoaded, setRazorpayLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => setRazorpayLoaded(true); // Set state when the script is loaded
    document.body.appendChild(script);
  }, []);

  const payNow = async () => {
    if (!amount) {
      alert("Please enter a valid amount!");
      return;
    }
    if (!razorpayLoaded) {
      console.error("Razorpay script not loaded yet");
      return;
    }

    const paymentAmount = amount * 100;

    try {
      const response = await fetch("https://sandeepanischools.in/create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: paymentAmount,
          currency: "INR",
          receipt: "receipt#1",
          notes: {},
        }),
      });

      const order = await response.json();

      const options = {
        key: "rzp_test_YKnmnF4Kun2z5B",
        amount: order.amount,
        currency: order.currency,
        name: "Sandeepani Group of Institution",
        description: "Test Transaction",
        order_id: order.id,
        // callback_url: "https://sandeepanischools.in",
        prefill: {
          name: "santhosh",
          email: "santhosh7banja@gmail.com",
          contact: "6309589838",
        },
        theme: {
          color: "#00008b",
        },
        handler: function (response) {
          // On successful payment
          navigate("/success", {
            state: { paymentId: response.razorpay_payment_id },
          });
        },
        modal: {
          ondismiss: function () {
            // On payment failure or dismissal
            navigate("/failure");
          },
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error during payment:", error);
      navigate("/failure");
    }
  };

  return (
    <div>
      <Header1 />
      {
        isAuthenticated ? (<div className="container-generate">
        <div className="home-title1">Generate Due Amount</div>
        <input
          type="text"
          placeholder="Admission Number"
          value={admissionNo}
          onChange={(e) => setAdmissionNo(e.target.value)}
        />
        {/* <input
          type="number"
          placeholder="Total Amount"
          value={totalAmount}
          onChange={(e) => setTotalAmount(e.target.value)}
        /> */}
        <button className="payment-button" onClick={handleGenerateAmount}>
          Generate Amount
        </button>

        {studentDetails && (
          <div className="student-details-generate">
            <h2>Student Details:</h2>
            <div className="detail-row">
              <span className="label-generate">Admission No:</span>
              <span className="value-generate">{studentDetails.admissionNo}</span>
            </div>
            <div className="detail-row">
              <span className="label-generate">Name:</span>
              <span className="value-generate">{studentDetails.name}</span>
            </div>
            <div className="detail-row">
              <span className="label-generate">Class:</span>
              <span className="value-generate">{studentDetails.class}</span>
            </div>
            <div className="detail-row">
              <span className="label-generate">Total Amount:</span>
              <span className="value-generate">{studentDetails.totalAmount}</span>
            </div>
            <div className="detail-row">
              <span className="label-generate">Total Payments:</span>
              <span className="value-generate">{studentDetails.totalPayments}</span>
            </div>
            <div className="detail-row">
              <span className="home-title2">Due Amount:</span>
              <span className="home-title1">{studentDetails.dueAmount}</span>
            </div>

            <h3>Payment History:</h3>
            {studentDetails.paymentHistory1 &&
            studentDetails.paymentHistory1.length > 0 ? (
              <ul>
                {studentDetails.paymentHistory1.map((payment, index) => (
                  <li key={index}>
                    <strong>Payment ID:</strong> {payment.paymentId} <br />
                    <strong>Amount:</strong> {payment.amount}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No payments found.</p>
            )}
            <br/>

            <div className="final-payment">
            <div className="home-title3">Pay now :</div>
            <input
          type="text"
          placeholder="paying amount"
          value={studentDetails.dueAmount}
          onChange={(e) => setAmount(e.target.value)}
        />      
              <button className="payment-button" onClick={payNow}>
                Pay Now
              </button>
            </div>
          </div>
        )}
      </div>) : (
        <div className="container-generate">
        <div className="home-title1">Please login to proceed for payments</div>
        </div>
      )
      }
      
      <Footer />
    </div>
  );
};

export default GenerateAmount;
